body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Inconsolata';
  src: local('Iconsolata-Bold'), url(./fonts/Inconsolata-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-ExtraBold'), url(./fonts/Montserrat-ExtraBold.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-Regular'), url(./fonts/Montserrat-Regular.ttf) format('truetype');
  font-weight: normal;
}

::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #FF0000;
}

.canvas {
  position: absolute;
  top: 0;
  pointer-events: none;
}

.scroll-container {
  position: absolute;
  overflow: auto;
  top: 0px;
  width: 100%;
  height: 100vh;
  font-size: 20em;
  font-weight: 800;
  line-height: 0.9em;
}