html, #root, body {
    background-color: white;
    height: 100%;
}

.App{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    max-height: 100vh;
}


::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #FF0000;
}